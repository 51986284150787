import * as React from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { addDays, subDays, subHours } from "date-fns";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";
import enUS from "date-fns/locale/en-US";
import { CrudAppointmentActions, useSchedules } from "./useSchedules";
import { CustomToolbar } from "./components/customToolbar/customToolbar";
import { CustomResourceHeader } from "./components/customResourceHeader";
import { CustomTimeSlotWrapper } from "./components/customTimeSlotWrapper";
import { CustomEvent } from "./components/customEvent/customEvent";
import "./schedules.css";
import { Drawer } from "app/components/drawer/drawer";
import { ReadAppointmentScreen } from "./components/crudAppointmentScreens/readAppointmentScreen/readAppointmentScreen";
import { CreateAppointmentScreen } from "./components/crudAppointmentScreens/createAppointmentScreen/createAppointmentScreen";

export interface SchedulesProps {}

const locales = {
  "en-US": enUS,
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 0 }), // week starts on Monday
  getDay,
  locales,
  addDays,
  subDays,
});

const DnDCalendar = withDragAndDrop(Calendar);

export const Schedules: React.FC<SchedulesProps> = (props) => {
  const {
    onNavigate,
    date,
    appointments,
    resourceMap,
    onUpdateAppointment,
    onNewSlotSelected,
    pendingAppointment,
    appointmentDrawer,
    setAppointmentDrawer,
    onAppointmentSelect,
    toggleAppointmentDrawer,
    selectedAppointmentEvent,
    setPendingAppointment,
    getSchedulesOfEmployees,
    currentShopTime,
  } = useSchedules();

  const events = [
    ...appointments,
    ...(pendingAppointment ? [pendingAppointment] : []),
  ];

  return (
    <div>
      <Drawer
        isOpen={appointmentDrawer.isOpen}
        setIsOpen={toggleAppointmentDrawer}
        backdrop={false}
        hideCloseBtn={appointmentDrawer.type === CrudAppointmentActions.READ}
        onClose={() => setAppointmentDrawer({ isOpen: false, type: null })}
      >
        {appointmentDrawer.type === CrudAppointmentActions.READ && (
          <ReadAppointmentScreen
            selectedAppointmentEvent={selectedAppointmentEvent}
            getSchedulesOfEmployees={getSchedulesOfEmployees}
            toggleAppointmentDrawer={toggleAppointmentDrawer}
            setPendingAppointment={setPendingAppointment}
            closeDrawer={() =>
              setAppointmentDrawer({ isOpen: false, type: null })
            }
          />
        )}
        {appointmentDrawer.type === CrudAppointmentActions.CREATE && (
          <CreateAppointmentScreen
            pendingAppointment={pendingAppointment}
            setPendingAppointment={setPendingAppointment}
            getSchedulesOfEmployees={getSchedulesOfEmployees}
            toggleAppointmentDrawer={toggleAppointmentDrawer}
          />
        )}
      </Drawer>
      <DnDCalendar
        date={date}
        getNow={() => currentShopTime}
        scrollToTime={subHours(currentShopTime, 2)}
        defaultView={Views.DAY}
        localizer={localizer}
        events={events} // add pending appointments
        draggableAccessor={(event) => true}
        resources={resourceMap}
        onEventDrop={onUpdateAppointment}
        onNavigate={onNavigate}
        resizable
        onSelectEvent={onAppointmentSelect}
        resourceIdAccessor={(event: any) => event.resourceId}
        resourceTitleAccessor={(resource: any) => resource.resourceTitle}
        selectable
        onSelectSlot={onNewSlotSelected}
        showMultiDayTimes
        dayLayoutAlgorithm={"no-overlap"}
        step={10}
        timeslots={6}
        className="relative"
        onDragOver={(e) => console.log(e)}
        components={{
          toolbar: CustomToolbar,
          resourceHeader: CustomResourceHeader,
          timeSlotWrapper: CustomTimeSlotWrapper,
          event: (props) => <CustomEvent {...props} />,
        }}
      />
    </div>
  );
};

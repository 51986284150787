import { Button, Input, Spinner } from "@nextui-org/react";
import * as React from "react";
import { useSignin } from "./useSignin";
import { Link } from "react-router-dom";
import { Path } from "app/path";
import { BrandInput } from "app/subframe/components/BrandInput";
import { AsButton } from "app/subframe";
import { Icon, twClassNames } from "@subframe/core";

export interface SigninProps {}

export const Signin: React.FC<SigninProps> = (props) => {
  const { handleSigninSubmit, loading, showPassword, setShowPassword } =
    useSignin();
  return (
    // <div className="flex flex-col h-screen justify-center items-center">
    //   <div className="flex flex-col w-full max-w-lg gap-8 border rounded p-10 shadow-lg">
    //     <h1 className="text-2xl text-center">Welcome to All Set</h1>
    //     <form onSubmit={handleSigninSubmit} className="flex flex-col gap-4 ">
    //       <Input
    //         placeholder="Email"
    //         name="email"
    //         type="email"
    //         validationBehavior={"native"}
    //         defaultValue={"gibbsoninfo+test1@gmail.com"}
    //         color="primary"
    //         classNames={{
    //           inputWrapper: "custom-input",
    //         }}
    //       />
    //       <Input
    //         placeholder="Password"
    //         name="password"
    //         validationBehavior={"native"}
    //         defaultValue={"password123"}
    //         type="password"
    //         color="primary"
    //         classNames={{
    //           inputWrapper: "custom-input",
    //         }}
    //       />

    //       <Button
    //         isDisabled={loading}
    //         color="primary"
    //         type="submit"
    //         className="mt-4 bg-black rounded"
    //       >
    //         {loading && <Spinner size="sm" color="default" />}
    //         Signin
    //       </Button>
    //       <Button
    //         as={Link}
    //         to={Path.SIGNUP}
    //         variant="light"
    //         className="rounded hover:!bg-black hover:text-white"
    //       >
    //         Sign up
    //       </Button>
    //     </form>
    //   </div>
    // </div>

    <form
      onSubmit={handleSigninSubmit}
      className="container max-w-none flex h-[100dvh] w-full flex-col items-center justify-center gap-4 bg-millet-500 py-12 mobile:px-4 mobile:py-12"
    >
      <div className="flex flex-col items-center justify-center gap-10 mobile:h-auto mobile:w-full mobile:flex-none">
        <div className="flex w-full flex-col items-center gap-6">
          <img
            className="h-6 w-28 flex-none object-cover"
            src="https://res.cloudinary.com/subframe/image/upload/v1727730304/uploads/3988/jkyyafpujkyze6ijiogy.svg"
          />
        </div>
        <div className="flex w-112 flex-col items-center gap-1 mobile:h-auto mobile:w-full mobile:flex-none">
          <BrandInput
            className="h-14 w-full flex-none"
            placeholder="Your email"
            textPosition="left"
            name="email"
          />
          <BrandInput
            className="h-14 w-full flex-none"
            placeholder="Password"
            textPosition="left"
            type={showPassword ? "text" : "password"}
            trailingComponent={
              <Icon
                className={twClassNames(
                  "text-body font-body text-neutral-500 inline-flex px-4"
                )}
                onClick={() => setShowPassword(!showPassword)}
                name={showPassword ? "FeatherEye" : "FeatherEyeOff"}
              />
            }
            name="password"
          />
        </div>
        <AsButton
          className="h-14 w-full flex-none"
          variant="brand"
          leadingIcon={null}
          trailingIcon={null}
          text="Log in"
          size="lg"
          loader={loading}
          type="submit"
        />
        <AsButton
          variant="link"
          leadingIcon={null}
          trailingIcon={null}
          text="Forgot password?"
          size="xs"
          loader={false}
          disabled={false}
          disabledSecondary={false}
          shadow={false}
        />
      </div>
    </form>
  );
};

import { Button, Input, Spinner } from "@nextui-org/react";
import { Path } from "app/path";
import * as React from "react";
import { Link } from "react-router-dom";
import { useSignup } from "./useSignup";
import { AsButton } from "app/subframe";
import { BrandInput } from "app/subframe/components/BrandInput";
import { Icon, twClassNames } from "@subframe/core";

export interface SignupProps {}

export const Signup: React.FC<SignupProps> = ({}) => {
  const {
    loading,
    handleOnSignupSubmit,
    showPassword,
    setShowPassword,
    showPasswordConfirmation,
    setShowPasswordConfirmation,
  } = useSignup();
  return (
    <form
      onSubmit={handleOnSignupSubmit}
      className="container max-w-none flex h-screen w-full flex-col items-center justify-center gap-4 bg-millet-500 py-12 mobile:px-4 mobile:py-12"
    >
      <div className="flex flex-col items-center justify-center gap-10 mobile:h-auto mobile:w-full mobile:flex-none">
        <div className="flex w-full flex-col items-center gap-6">
          <img
            className="h-6 w-28 flex-none object-cover"
            src="https://res.cloudinary.com/subframe/image/upload/v1727730304/uploads/3988/jkyyafpujkyze6ijiogy.svg"
          />
          <span className="text-body-bold font-body-bold text-neutral-700">
            Create your All Set account.
          </span>
        </div>
        <div className="flex w-112 flex-col items-center gap-1 mobile:h-auto mobile:w-full mobile:flex-none">
          <BrandInput
            className="h-14 w-full flex-none"
            placeholder="Your email *"
            required
            textPosition="left"
            name="email"
          />
          <BrandInput
            className="h-14 w-full flex-none"
            placeholder="Password *"
            required
            textPosition="left"
            name="password"
            type={showPassword ? "text" : "password"}
            trailingComponent={
              <Icon
                className={twClassNames(
                  "text-body font-body text-neutral-500 inline-flex px-4"
                )}
                onClick={() => setShowPassword(!showPassword)}
                name={showPassword ? "FeatherEye" : "FeatherEyeOff"}
              />
            }
          />
          <BrandInput
            className="h-14 w-full flex-none"
            placeholder="Confirm password *"
            required
            textPosition="left"
            name="passwordConfirmation"
            type={showPasswordConfirmation ? "text" : "password"}
            trailingComponent={
              <Icon
                className={twClassNames(
                  "text-body font-body text-neutral-500 inline-flex px-4"
                )}
                onClick={() =>
                  setShowPasswordConfirmation(!showPasswordConfirmation)
                }
                name={showPasswordConfirmation ? "FeatherEye" : "FeatherEyeOff"}
              />
            }
          />
        </div>
        <AsButton
          className="h-14 w-full flex-none"
          variant="brand"
          leadingIcon={null}
          trailingIcon={null}
          text="Continue"
          size="lg"
          loader={loading}
        />
      </div>
    </form>
  );
};

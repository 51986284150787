import * as React from "react";
import { useProtectedRoutes } from "./useProtectedRoutes";
import classnames from "classnames";
import styles from "./protectedRoutes.module.css";
import { Loading } from "app/components/loading/loading";
import { Navbar } from "app/components/navbar/navbar";
import { useBreakpoint } from "app/utils/useBreakpoint";

export interface ProtectedRouteProps {
  children?: any;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { user, location, shouldShowNavbar, areScheduleRoutes } =
    useProtectedRoutes();

  const { isBelowSm, isAboveSm } = useBreakpoint("sm");

  if (!user) {
    return <Loading />;
  }

  return (
    <div className={styles.base}>
      <div className={classnames("relative overflow-hidden", {})}>
        <div className="flex h-full">
          {shouldShowNavbar && <Navbar />}
          <div
            className={classnames("min-h-screen", {
              "ml-14": shouldShowNavbar && isAboveSm,
              // "pb-20": isBelowSm,
              "w-full": !areScheduleRoutes,
              "w-screen": areScheduleRoutes,
            })}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

import {
  Button,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Skeleton,
} from "@nextui-org/react";
import * as React from "react";
import {
  PaymentTypes,
  tipOptions,
  usePaymentCheckout,
} from "./usePaymentCheckout";
import { round } from "lodash";
import {
  ChevronLeftIcon,
  EllipsisHorizontalIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { CashCheckout } from "./components/cashCheckout/cashCheckout";
import { CcCheckout } from "./components/ccCheckout/ccCheckout";
import {
  AsIconButton,
  AsInput,
  AsButton,
  CardInfo,
  PaymentModalHeader,
  TipChip,
  SkeletonText,
  LineItems,
} from "app/subframe";
import { SaleStatusConditionTypes } from "app/sales/useSales";
import { useBreakpoint } from "app/utils/useBreakpoint";
import classNames from "classnames";

export interface PaymentCheckoutProps {
  saleId: number;
  saleStatusCondition: SaleStatusConditionTypes | null;
  onCloseDrawer: () => void;
  onArchiveSale: () => void;
}

export const PaymentCheckout: React.FC<PaymentCheckoutProps> = ({
  saleId,
  saleStatusCondition,
  onCloseDrawer,
  onArchiveSale,
}) => {
  const {
    tipsCents,
    setTipsCents,
    total,
    toOpenInfoScreen,
    payWithMode,
    setPayWithMode,
    recordPaidPayment,
    remainingBalance,
    remainingBalanceCents,
    sale,
    isLoadingSale,
    onPostPaymentRecord,
    closeSale,
    processingCreditCardCharge,
    payWithExistingCard,
    selectedTipOption,
    setSelectedTipOption,
    showOtherTipInput,
    setShowOtherTipInput,
  } = usePaymentCheckout({ saleId, saleStatusCondition });

  const { isBelowSm, isAboveSm } = useBreakpoint("sm");

  const getPaymentModalHeaderText = () => {
    switch (saleStatusCondition) {
      case SaleStatusConditionTypes.REMAINING_BALANCE:
        return "Collect remaining balance with the methods below";
      case SaleStatusConditionTypes.PAID_ONLINE_WITHOUT_TIPS:
        return "Collect tips with the methods below";
      default:
        return "Pay with";
    }
  };

  const getPaymentModalHeaderDisclaimer = () => {
    switch (saleStatusCondition) {
      case SaleStatusConditionTypes.REMAINING_BALANCE:
        return "Collect remaining balance by using the above form field or via self checkout.";
      case SaleStatusConditionTypes.PAID_ONLINE_WITHOUT_TIPS:
        return "For sales with $0 balance due, tips can be added using the above form field or accepted via self checkout.";
      default:
        return null;
    }
  };

  const paymentModalHeaderText = getPaymentModalHeaderText();
  const paymentModalHeaderDisclaimer = getPaymentModalHeaderDisclaimer();

  const getSaleStatusConditionData = () => {
    switch (saleStatusCondition) {
      case SaleStatusConditionTypes.PAID_ONLINE_WITHOUT_TIPS:
        return {
          disclaimerText: null,
          onButtonClick: () => {
            payWithExistingCard();
          },
          buttonText: "Complete tips payment with",
        };
      case SaleStatusConditionTypes.REMAINING_BALANCE:
        return {
          disclaimerText: null,
          buttonText: "Collect remaining balance with",
          onButtonClick: () => {
            payWithExistingCard();
          },
        };
      case SaleStatusConditionTypes.PRE_AUTHORIZED:
        return {
          disclaimerText: null,
          buttonText: "Charge with pre authorized card",
          onButtonClick: () => {
            payWithExistingCard();
          },
        };
      default:
        return null;
    }
  };

  const saleStatusConditionData = getSaleStatusConditionData();

  const PayWithOptions = () => {
    const memoizedCashCheckout = React.useMemo(
      () => (
        <CashCheckout
          initialTotalCents={remainingBalanceCents}
          recordPaidPayment={recordPaidPayment}
          setPayWithMode={setPayWithMode}
        />
      ),
      [remainingBalanceCents, recordPaidPayment, setPayWithMode]
    );
    const memoizedCcCheckout = React.useMemo(
      () => (
        <CcCheckout
          initialTotalCents={remainingBalanceCents}
          recordPaidPayment={recordPaidPayment}
          setPayWithMode={setPayWithMode}
          total={total}
          tipsCents={tipsCents}
          totalCents={remainingBalanceCents}
          onPostPaymentRecord={onPostPaymentRecord}
          saleStatusCondition={saleStatusCondition}
          onCloseDrawer={onCloseDrawer}
        />
      ),
      [
        remainingBalanceCents,
        recordPaidPayment,
        setPayWithMode,
        saleStatusCondition,
      ]
    );

    switch (payWithMode) {
      case PaymentTypes.CASH:
        return memoizedCashCheckout;
      case PaymentTypes.CREDIT_CARD:
        return memoizedCcCheckout;
      default:
        switch (saleStatusCondition) {
          case SaleStatusConditionTypes.PAID_ONLINE_WITH_TIPS:
            return (
              <>
                <span className="text-body-bold font-body-bold text-neutral-900">
                  Everything looks good?
                </span>
                <AsButton
                  className="h-10 w-full flex-none"
                  variant="default"
                  leadingIcon={null}
                  trailingIcon={null}
                  text="Complete sale"
                  size="default"
                  loader={false}
                  onClick={() => closeSale()}
                />
              </>
            );
          // case SaleStatusConditionTypes.PAID_ONLINE_WITHOUT_TIPS:
          //   // setPayWithMode(PaymentTypes.CREDIT_CARD);
          //   return <></>;
          // case SaleStatusConditionTypes.REMAINING_BALANCE:
          //   // setPayWithMode(PaymentTypes.CREDIT_CARD);
          //   return <></>;
          default:
            return (
              <>
                {paymentModalHeaderDisclaimer && (
                  <>
                    <span className="w-full text-body-bold font-body-bold text-neutral-900">
                      {paymentModalHeaderDisclaimer}
                    </span>
                    <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-100" />
                  </>
                )}
                <PaymentModalHeader
                  text={paymentModalHeaderText}
                  size="small"
                  buttonProps={{
                    onClick: () => setPayWithMode(null),
                    className: classNames({
                      hidden: !payWithMode,
                    }),
                  }}
                />
                <div
                  className={classNames(
                    "flex w-full items-center mobile:flex-col",
                    {
                      "gap-3":
                        saleStatusCondition ===
                          SaleStatusConditionTypes.PAID_ONLINE_WITHOUT_TIPS &&
                        isBelowSm,
                      "gap-4": !(
                        saleStatusCondition ===
                          SaleStatusConditionTypes.PAID_ONLINE_WITHOUT_TIPS &&
                        isBelowSm
                      ), // Default gap
                    }
                  )}
                >
                  <AsButton
                    className="h-10 grow shrink-0 basis-0 w-full"
                    variant="secondary"
                    leadingIcon="FeatherCreditCard"
                    text="Credit Card"
                    size="default"
                    onClick={() => setPayWithMode(PaymentTypes.CREDIT_CARD)}
                  />
                  <AsButton
                    className="h-10 grow shrink-0 basis-0 w-full"
                    variant="secondary"
                    leadingIcon="FeatherBanknote"
                    text="Cash"
                    size="default"
                    onClick={() => setPayWithMode(PaymentTypes.CASH)}
                  />
                  {saleStatusCondition ===
                    SaleStatusConditionTypes.PAID_ONLINE_WITHOUT_TIPS && (
                    <AsButton
                      className="h-10 grow shrink-0 basis-0 w-full"
                      variant="secondary"
                      text="No tips"
                      size="default"
                      onClick={() => closeSale()}
                    />
                  )}
                </div>
              </>
            );
        }
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center pb-3 relative gap-2 ">
        <AsIconButton
          className=" w-4 h-4 top-1.5 hover:!bg-transparent"
          variant="ghost"
          onClick={() => toOpenInfoScreen()}
          leadingIcon={"FeatherChevronLeft"}
        />
        <p className="font-semibold absolute left-1/2 -translate-x-1/2">
          Payments
        </p>
        <div className="flex items-center gap-4">
          <Dropdown
            classNames={{
              content: "rounded-sm",
            }}
          >
            <DropdownTrigger>
              <Button
                variant="light"
                isIconOnly
                className="rounded p-1 w-7 h-7"
              >
                <EllipsisHorizontalIcon />
              </Button>
            </DropdownTrigger>
            <DropdownMenu>
              <DropdownItem
                variant="flat"
                key={"delete"}
                className="rounded-sm text-red-500"
                color="danger"
                onClick={() => onArchiveSale()}
              >
                Delete
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <AsIconButton
            variant="ghost"
            className="rounded p-1 w-6 h-6"
            onClick={() => onCloseDrawer()}
          >
            <XMarkIcon />
          </AsIconButton>
        </div>
      </div>
      <Divider className="mt-4 mb-6" />
      <div className="flex flex-col gap-6 relative">
        <LineItems
          title="Subtotal"
          price={sale?.subtotal}
          isLoading={isLoadingSale}
        />
        <LineItems
          title="Tax"
          price={sale?.tax || "$0.00"}
          isLoading={isLoadingSale}
        />
        {isBelowSm ? (
          <div className="flex w-full flex-col items-center gap-2 mobile:flex-col mobile:gap-2">
            <div className="flex w-full items-center gap-4">
              <span className="grow shrink-0 basis-0 text-body font-body text-neutral-700">
                Tips
              </span>

              <span className="text-[16px] font-[600] leading-[24px] text-neutral-900">
                {`$${round(tipsCents / 100, 2).toFixed(2)}`}
              </span>
            </div>
            <div className="flex w-full items-center gap-2 mobile:flex-row mobile:gap-2">
              {showOtherTipInput ? (
                <AsInput
                  type="number"
                  onChange={(e: any) => setTipsCents(e.target.value * 100)}
                  className="h-10 w-full"
                  leading="$"
                  textPosition="left"
                  placeholder={"0"}
                  trailingComponent={
                    <AsIconButton
                      className="m-1 w-8 h-8 flex-none"
                      variant="default"
                      leadingIcon="FeatherCheck"
                      text="Label"
                      size="default"
                      onClick={() => {
                        setShowOtherTipInput(false);
                      }}
                    />
                  }
                />
              ) : (
                tipOptions?.map((option, index) => {
                  return (
                    <TipChip
                      text={option.label}
                      active={selectedTipOption === option}
                      onClick={() => {
                        setSelectedTipOption(option);
                        setTipsCents(sale?.subtotalCents * option.value);
                        if (option.label === "Other") {
                          setShowOtherTipInput(true);
                        }
                      }}
                    />
                  );
                })
              )}
            </div>
          </div>
        ) : (
          <div className={"group/6a5f736e flex w-full items-center gap-4"}>
            <div className="flex grow shrink-0 basis-0 items-center gap-1">
              <div className="flex items-center gap-4">
                <span className={"text-body font-body text-neutral-700"}>
                  Tips
                </span>
                {tipOptions?.map((option, index) => {
                  if (option.label === "None" || option.label === "Other") {
                    return;
                  }
                  return (
                    <AsButton
                      variant="ghost"
                      text={option.label}
                      size="xs"
                      onClick={() =>
                        setTipsCents(sale?.subtotalCents * option.value)
                      }
                    />
                  );
                })}
              </div>
            </div>
            <AsInput
              type="number"
              value={Number((tipsCents / 100).toFixed(2))}
              onChange={(e: any) => setTipsCents(e.target.value * 100)}
              className="h-12 w-32 flex-none"
              leading="$"
              placeholder={"0"}
            />
          </div>
        )}

        <LineItems
          title="Total"
          price={`$${total}`}
          isLoading={isLoadingSale}
        />
        <Divider />
        <LineItems
          title="Paid online"
          price={sale?.paidOnlineAmount}
          isLoading={isLoadingSale}
        />
        <LineItems
          title="Remaining balance"
          price={`$${remainingBalance}`}
          isLoading={isLoadingSale}
        />
        <Divider />
        {saleStatusConditionData && (
          <AsButton
            className="h-12 w-full flex-none"
            variant="default"
            text={saleStatusConditionData.buttonText}
            size="md"
            loader={processingCreditCardCharge}
            cardComponent={
              isLoadingSale ? (
                <SkeletonText className="w-24" />
              ) : (
                <CardInfo
                  variant="inverse"
                  image="https://res.cloudinary.com/subframe/image/upload/v1726598383/uploads/3988/dvl5twcraiwmn914brec.png"
                  text={sale.client.lastPaymentTransaction?.last4}
                />
              )
            }
            onClick={saleStatusConditionData.onButtonClick}
          />
        )}
        <div className="flex w-full flex-col items-start justify-center gap-4 rounded-md border border-solid border-neutral-100 bg-neutral-0 px-3 py-4 mobile:pt-0 mobile:border-0 mobile:bg-none">
          <PayWithOptions />
        </div>
      </div>
    </div>
  );
};

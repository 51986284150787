import { format } from "date-fns";
import * as React from "react";
import { useCreateAppointmentScreen } from "./useCreateAppointmentScreen";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { Button, Input } from "@nextui-org/react";
import { TrashIcon } from "@heroicons/react/24/outline";
import { adjustDateToUTC } from "app/utils/formatTime";
import { CustomClientOption } from "app/schedules/components/crudAppointmentScreens/components/customClientOption";
import { CustomServiceOption } from "app/schedules/components/crudAppointmentScreens/components/customServiceOption";
import { CustomEmployeeOption } from "app/schedules/components/crudAppointmentScreens/components/customEmployeeOption";
import { formatAsUTC } from "app/utils/formatDate";
import { Avatar } from "app/components/avatar/avatar";

export interface CreateAppointmentScreenProps {
  pendingAppointment: any;
  setPendingAppointment: (pendingAppointment: any) => void;
  toggleAppointmentDrawer: (open: boolean) => void;
  getSchedulesOfEmployees: ({ currentDate }: { currentDate?: Date }) => void;
}

export const CreateAppointmentScreen: React.FC<
  CreateAppointmentScreenProps
> = ({
  pendingAppointment,
  setPendingAppointment,
  toggleAppointmentDrawer,
  getSchedulesOfEmployees,
}) => {
  const {
    employee,
    employees,
    clients,
    isDateInputFocused,
    setIsDateInputFocused,
    onSubmit,
    onHandleClientChange,
    selectedClient,
    setSelectedClient,
    selectedServices,
    onHandleAddService,
    selectedDate,
    setSelectedDate,
    availableServices,
    serviceSelectValue,
    editingEmployeeInServiceIndex,
    setEditingEmployeeInServiceIndex,
    handleEmployeeChange,
    employeeSelectRef,
    onRemoveService,
    handleClientSearch,
    onDateChange,
    onTimeChange,
  } = useCreateAppointmentScreen({
    pendingAppointment,
    setPendingAppointment,
    toggleAppointmentDrawer,
    getSchedulesOfEmployees,
  });

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col gap-2 mt-1.5 mb-7">
        <p className="text-body-bold font-body-bold text-neutral-900">
          Create Appointment for
        </p>
      </div>
      <form className="flex flex-col relative h-[90vh]" onSubmit={onSubmit}>
        <div className="flex items-center gap-2.5 mb-6">
          <Input
            aria-label="Date Input"
            key={isDateInputFocused ? "is-focused-date" : "is-not-focused-date"}
            name="date"
            defaultValue={
              isDateInputFocused
                ? formatAsUTC(new Date(selectedDate), "yyyy-MM-dd")
                : formatAsUTC(new Date(selectedDate), "EEE, MMM d")
            }
            type={isDateInputFocused ? "date" : "string"}
            variant="bordered"
            onFocus={() => setIsDateInputFocused(true)}
            onChange={(e) => onDateChange(new Date(e.target.value))}
            onBlur={(e: any) => {
              const dateValue = e.target.value;
              if (dateValue) {
                const adjustedDate = adjustDateToUTC(dateValue);
                setSelectedDate(adjustedDate.toISOString());
              }
              setIsDateInputFocused(false);
            }}
            classNames={{
              input: "font-semibold text-sm text-zinc-700",
              inputWrapper: "custom-input py-6 !border-2",
            }}
            startContent={
              <span className="text-zinc-500 text-sm font-semibold">On</span>
            }
            className="text-sm text-zinc-500 font-semibold border-zinc-100 bg-transparent"
          />
          <Input
            aria-label="Start time Input"
            defaultValue={
              pendingAppointment
                ? format(pendingAppointment?.start, "HH:mm")
                : "09:00"
            }
            onChange={(e) => onTimeChange(e.target.value)}
            type="time"
            name="startTime"
            variant="bordered"
            classNames={{
              input: "font-semibold text-sm text-zinc-700",
              inputWrapper: "custom-input py-6 !border-2",
            }}
            startContent={
              <span className="text-zinc-500 whitespace-nowrap text-sm font-semibold">
                Start at
              </span>
            }
            className="text-sm text-zinc-500 font-semibold border-zinc-100 bg-transparent"
          />
        </div>
        <div className="flex flex-col gap-6">
          {selectedClient ? (
            <div className="flex flex-col relative">
              <Button
                isIconOnly
                variant="light"
                onClick={() => setSelectedClient(null)}
                className="rounded absolute top-0 right-0"
              >
                <TrashIcon className="w-4 h-4 text-zinc-500" />
              </Button>
              <div className="flex pb-4">
                <Avatar
                  src={selectedClient.avatar}
                  name={selectedClient.name}
                  type="character"
                  size={56}
                />
                <div className="flex flex-col justify-between ml-4">
                  <p className="font-semibold pt-0.5">{selectedClient.name}</p>
                  <p className="text-zinc-500 text-sm">
                    Client since {format(selectedClient.createdAt, "M.d.yyyy")}
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-4">
                  <p className="text-zinc-500 w-[56px] text-sm">Phone:</p>
                  <p className="font-semibold ">
                    {selectedClient.phone || "(###) ###-####"}
                  </p>
                </div>
                <div className="flex items-center gap-4">
                  <p className="text-zinc-500 w-[56px] text-sm">Email:</p>
                  <p className="font-semibold ">
                    {selectedClient.email || "###"}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <AsyncSelect
              cacheOptions
              loadOptions={handleClientSearch as any}
              onChange={onHandleClientChange}
              className="text-zinc-500 text-sm"
              placeholder="Search or select a client"
              components={{
                Option: CustomClientOption,
              }}
              defaultOptions={clients.map((c) => ({
                value: c.id,
                label: c.name,
                phone: c.phone,
                email: c.email,
                createdAt: c.createdAt,
                avatar: c.avatar,
              }))}
              // Optionally, you can add a value prop if needed
            />
          )}
          {selectedServices &&
            selectedServices.map((service: any, index: any) => (
              <div className="flex items-center justify-between" key={index}>
                <div className="flex flex-col gap-2">
                  <p className="text-body-bold font-body-bold text-neutral-900">
                    {service.title}
                  </p>
                  <div className="flex gap-4">
                    <div className="flex gap-1 text-sm">
                      <span className="text-zinc-500 text-sm">with</span>
                      <div
                        className="font-semibold text-zinc-900 cursor-pointer relative hover:underline"
                        onClick={() => setEditingEmployeeInServiceIndex(index)}
                      >
                        {editingEmployeeInServiceIndex === index ? (
                          <div
                            ref={employeeSelectRef}
                            className="absolute -top-2 w-[200px]"
                          >
                            <Select
                              placeholder="Select an employee"
                              components={{
                                Option: CustomEmployeeOption,
                              }}
                              onChange={(selectedOption) =>
                                handleEmployeeChange(index, selectedOption)
                              }
                              className="text-zinc-500 text-sm"
                              options={employees.map((emp) => ({
                                value: emp.id,
                                label: emp.name,
                                services: emp.services,
                                selectedDate,
                                availabilities: emp.availabilities,
                                businessServiceId:
                                  selectedServices[index]?.businessService.id, // Pass the specific business service ID
                              }))}
                              onBlur={() =>
                                setEditingEmployeeInServiceIndex(null)
                              }
                              value={
                                selectedServices[index]?.employee
                                  ? {
                                      value:
                                        selectedServices[index]?.employee?.id,
                                      label:
                                        selectedServices[index]?.employee?.name,
                                    }
                                  : null
                              }
                              menuIsOpen={
                                editingEmployeeInServiceIndex === index
                              }
                            />
                          </div>
                        ) : (
                          service?.employee?.name
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <p className="text-body-bold font-body-bold text-neutral-900">
                    {service.price}
                  </p>

                  <button
                    type="button"
                    className="text-red-500"
                    onClick={() => onRemoveService(index)}
                  >
                    <TrashIcon className="w-4 h-4" />
                  </button>
                </div>
              </div>
            ))}
          <Select
            placeholder="Select a service"
            components={{
              Option: CustomServiceOption,
            }}
            onChange={onHandleAddService}
            value={serviceSelectValue}
            className="text-zinc-500 text-sm"
            options={availableServices?.map((category) => ({
              label: category.name,
              options: category.services.map((service: any) => ({
                value: service.id,
                label: service.title,
                price: service.price,
                status: service.status,
                employee,
              })),
            }))}
          />
        </div>
        <Button
          type="submit"
          className="rounded-sm w-full bottom-0 bg-black text-white absolute"
        >
          Create appointment
        </Button>
      </form>
    </div>
  );
};

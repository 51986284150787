import * as React from "react";
import { useClientDrawer } from "../useClientDrawer";
import {
  Button,
  Input,
  Radio,
  RadioGroup,
  Spinner,
  Textarea,
} from "@nextui-org/react";
import { PlusIcon } from "@heroicons/react/24/solid";
import PhoneInput from "react-phone-input-2";

export interface AddClientProps {
  setCloseParent?: (isOpen: boolean) => void;
}

export const AddClient: React.FC<AddClientProps> = ({ setCloseParent }) => {
  const {
    onSubmit,
    isCreatingClient,
    profileImagePreview,
    handleProfileImageChange,
  } = useClientDrawer({ setCloseParent });

  return (
    <form
      onSubmit={onSubmit}
      className="flex flex-col gap-6 mobile:h-[80dvh] mobile:pb-80"
    >
      <p className="font-semibold text-gray-800">Add Client</p>

      <div className="flex flex-col justify-center rounded pointer bg-zinc-100 items-center border border-dashed w-[100px] h-[100px] gap-4">
        {profileImagePreview ? (
          <img
            src={profileImagePreview}
            alt="Profile Preview"
            className="h-[100px] w-[100px] rounded border"
          />
        ) : (
          <label
            htmlFor="profile-upload"
            className="flex flex-col gap-3 w-[100px] text-center h-[100px] bg-zinc-100 items-center justify-center border-dashed border-2 p-[10px] rounded cursor-pointer"
          >
            <PlusIcon className="h-5 w-5 stroke-black" />
            <p className=" text-sm">Upload Profile</p>
          </label>
        )}
        <input
          type="file"
          accept="image/*"
          onChange={handleProfileImageChange}
          className="hidden"
          id="profile-upload"
          name="profileImage"
        />
      </div>

      <Input
        classNames={{
          label: "!text-zinc-700 font-semibold",
          inputWrapper:
            "bg-transparent rounded shadow-sm border border-[#F4F4F5]",
          input: "font-semibold placeholder:font-normal mobile:text-medium",
        }}
        labelPlacement="outside"
        variant="bordered"
        className="text-[#71717A]"
        label="First name"
        name="firstName"
        placeholder="Enter first name"
        validationBehavior="native"
        isRequired
      />
      <Input
        classNames={{
          label: "!text-zinc-700 font-semibold",
          inputWrapper: "custom-input",
          input: "font-semibold placeholder:font-normal mobile:text-medium",
        }}
        labelPlacement="outside"
        variant="bordered"
        className="text-[#71717A]"
        label="Last name"
        name="lastName"
        placeholder="Enter last name"
        validationBehavior="native"
        isRequired
      />
      <div className="flex flex-col gap-2">
        <Input
          label="Email"
          labelPlacement="outside"
          validationBehavior="native"
          isRequired
          name="email"
          type="email"
          placeholder="Enter email"
          classNames={{
            inputWrapper: "custom-input",
            label: "font-semibold",
            input: "font-semibold placeholder:font-normal mobile:text-medium",
          }}
        />
      </div>
      <div className="flex flex-col gap-2">
        <p className="text-body-bold font-body-bold text-neutral-900">Phone</p>
        <PhoneInput
          inputProps={{ name: "phone" }}
          specialLabel=""
          placeholder="+1 (555) 555-5555"
          inputClass="custom-input !text-medium"
        />
      </div>
      <div className="flex flex-col gap-2">
        <RadioGroup
          label="Gender"
          name="gender"
          orientation="horizontal"
          isRequired
          validationBehavior="native"
          classNames={{
            wrapper: "flex justify-between w-full pr-4",
            label: "!text-zinc-700 font-semibold",
          }}
        >
          <Radio
            color="default"
            classNames={{
              label: "!text-zinc-700 font-semibold text-md ml-4",
            }}
            value="female"
          >
            Female
          </Radio>
          <Radio
            color="default"
            classNames={{
              label: "!text-zinc-700 font-semibold text-md ml-4",
            }}
            value="male"
          >
            Male
          </Radio>
          <Radio
            color="default"
            classNames={{
              label: "!text-zinc-700 font-semibold text-md ml-4",
            }}
            value="non_binary"
          >
            Non-Binary
          </Radio>
        </RadioGroup>
      </div>
      <Textarea
        variant="bordered"
        label="Notes"
        labelPlacement="outside"
        placeholder="Enter client notes"
        name="note"
        classNames={{
          label: "!text-zinc-700 font-semibold text-md",
          inputWrapper:
            "bg-transparent rounded shadow-sm border border-[#F4F4F5] !h-[172px]",
          input: "font-semibold placeholder:font-normal mobile:text-medium",
        }}
      />
      <div className="flex mobile:pb-14">
        <Button
          radius="none"
          className="w-full font-semibold h-12 bg-zinc-300 "
          onClick={() => setCloseParent?.(false)}
          isDisabled={isCreatingClient}
        >
          Cancel
        </Button>
        <Button
          radius="none"
          className="w-full font-semibold h-12 bg-black text-white"
          type="submit"
          isDisabled={isCreatingClient}
        >
          {isCreatingClient && <Spinner className="w-6 h-6" />}
          Add
        </Button>
      </div>
    </form>
  );
};

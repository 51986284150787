import * as React from "react";
import { useClosedSaleScreen } from "./useClosedSaleScreen";
import {
  Button,
  Chip,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Spinner,
} from "@nextui-org/react";
import { format } from "date-fns";
import { titleize } from "app/utils/string";
import {
  EllipsisHorizontalIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { AsButton, AsIconButton, CardInfo, LineItems } from "app/subframe";
import { PaymentMethod } from "app/redux/paymentTransactionSlice";
import { SaleStatusTypes } from "app/redux/salesSlice";
import { Icon } from "@subframe/core";
import { Avatar } from "app/components/avatar/avatar";

export interface ClosedSaleScreenProps {
  setShouldShowDrawer: (value: boolean) => void;
}

export const ClosedSaleScreen: React.FC<ClosedSaleScreenProps> = ({
  setShouldShowDrawer,
}) => {
  const { sale, loading, onVoidSale, loadingReason, onCloneSale, onOpenSale } =
    useClosedSaleScreen();

  if (loading || !sale.client) {
    return (
      <Spinner
        label={loadingReason}
        size="lg"
        className="absolute left-1/2 top-1/3 -translate-x-1/2"
      />
    );
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center gap-3 mt-3 mb-6 justify-between">
        <div className="flex gap-3">
          <p className="text-body-bold font-body-bold text-neutral-900">
            Sale #{sale.id}
          </p>
          <Chip
            color="default"
            className={classNames("w-11 rounded-sm text-sm", {
              "bg-[#e4d4f4]": sale.status === SaleStatusTypes.VOID,
              "bg-[#c2cfd2]": sale.status === SaleStatusTypes.CLOSED,
            })}
          >
            {titleize(sale.status)}
          </Chip>
        </div>
        <div className="flex items-center gap-4">
          {sale.status !== SaleStatusTypes.VOID && (
            <Dropdown
              classNames={{
                content: "rounded-sm",
              }}
            >
              <DropdownTrigger>
                <Button
                  variant="light"
                  isIconOnly
                  className="rounded p-1 w-7 h-7"
                >
                  <EllipsisHorizontalIcon />
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem
                  variant="flat"
                  key={SaleStatusTypes.VOID}
                  className="rounded-sm"
                  color="default"
                  onClick={onVoidSale}
                >
                  Void
                </DropdownItem>
                <DropdownItem
                  variant="flat"
                  key={SaleStatusTypes.OPEN}
                  className="rounded-sm"
                  color="default"
                  onClick={onOpenSale}
                >
                  Reopen
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
          <AsIconButton
            variant="ghost"
            className="rounded p-1 w-6 h-6"
            onClick={() => setShouldShowDrawer(false)}
          >
            <XMarkIcon />
          </AsIconButton>
        </div>
      </div>
      {sale.status === SaleStatusTypes.VOID && (
        <div className="flex flex-col p-4 gap-1 border border-[#E4E4E7] bg-[#FAFAFA] rounded">
          <div className="flex items-center">
            <ExclamationTriangleIcon className="w-5 h-5 stroke-[#F5A524] mr-1" />
            <p className="font-semibold text-sm">
              You've voided this transaction
            </p>
          </div>
          <p className="text-sm mb-4">
            This transaction is cancelled, and the payment will not be <br />{" "}
            processed. If you want to create a new payment for this sale, <br />{" "}
            press the{" "}
            <span className="text-body-bold font-body-bold text-neutral-900">
              “Clone this sale”
            </span>{" "}
            button below.
          </p>
          <Button
            variant="bordered"
            className="bg-white  rounded-sm w-full border-black font-semibold p-[10px]"
            onClick={() => onCloneSale(sale)}
          >
            Clone this sale
          </Button>
        </div>
      )}
      <div className="flex items-center gap-5">
        <Avatar
          name={sale.client?.name}
          src={sale.client?.avatar}
          type="character"
          size={56}
        />
        <div className="flex flex-col gap-2 my-6">
          <p className="text-body-bold font-body-bold text-neutral-900">
            {sale.client?.name}
          </p>
          <p className="text-zinc-500 text-sm">
            Client since {format(sale.client?.createdAt, "M.dd.yyyy")}
          </p>
        </div>
      </div>
      <Divider />
      <div className="flex flex-col gap-6 pt-6">
        {sale.salesMerchantServices.map((service: any) => (
          <div className="flex items-center justify-between" key={service.id}>
            <div className="flex flex-col gap-2" key={service.id}>
              <p className="text-body-bold font-body-bold text-neutral-900">
                {service.title}
              </p>
              <div className="flex">
                <div className="flex gap-1 text-sm">
                  <span className="text-zinc-500 text-sm">with</span>
                  <div className="font-semibold text-zinc-900 cursor-pointer relative hover:underline">
                    {service?.employee?.name}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <p className="text-body-bold font-body-bold text-neutral-900">
                {service.price}
              </p>
            </div>
          </div>
        ))}
        <Divider />
      </div>
      <div className="flex flex-col py-6 gap-6">
        <LineItems title="Subtotal" price={sale.subtotal} />
        <LineItems title="Tax" price={sale.paidTax} />
        <LineItems title="Tip" price={sale.paidTips} />
        <LineItems title="Total" price={sale.total} />
        <Divider />
        <div className="flex w-full items-center gap-4">
          <div className="flex grow shrink-0 basis-0 items-center gap-4">
            <span className="text-body font-body text-neutral-700">
              Paid with
            </span>
            {sale.paymentTransactions
              .reduce((acc: any[], transaction: any) => {
                // Log each transaction to debug

                // // Check if last4 is null or already rendered
                if (acc.some((item) => item.last4 === transaction.last4)) {
                  return acc; // Skip this transaction
                }

                let element;
                if (transaction.paymentMethod === PaymentMethod.CASH) {
                  element = (
                    <div className="flex items-center gap-1">
                      <Icon name="FeatherBanknote" />
                      <p className="text-body font-body text-neutral-700">
                        Cash
                      </p>
                    </div>
                  );
                } else {
                  if (!transaction.cardType) {
                    element = (
                      <div className="flex items-center gap-1">
                        <Icon name="FeatherCreditCard" />
                        <p className="text-body font-body text-neutral-700">
                          Card
                        </p>
                      </div>
                    );
                  } else {
                    element = (
                      <CardInfo
                        image="https://res.cloudinary.com/subframe/image/upload/v1726598383/uploads/3988/dvl5twcraiwmn914brec.png"
                        text={transaction.last4}
                      />
                    );
                  }
                }

                // Push the element and last4 to the accumulator
                if (element) {
                  acc.push({ last4: transaction.last4, element });
                }
                return acc;
              }, [])
              .map((item: any) => item.element)}
          </div>

          <span className="text-[16px] font-[600] leading-[24px] text-neutral-900">
            <p className="text-body-bold font-body-bold text-neutral-900">
              {sale.total}
            </p>
          </span>
        </div>
      </div>
    </div>
  );
};

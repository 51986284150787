import * as React from "react";
import { perPageList, sortByList, useClients } from "./useClients";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import {
  PlusIcon,
  ChevronDownIcon,
  CheckIcon,
} from "@heroicons/react/24/solid";
import { ClientTable } from "./components/clientTable/clientTable";
import classNames from "classnames";
import { titleize } from "app/utils/string";
import { Drawer } from "app/components/drawer/drawer";
import { AddClient } from "./components/drawer/addClient/addClient";
import { UpdateClient } from "./components/drawer/updateClient/updateClient";
import { Loading } from "app/components/loading/loading";

export interface ClientsProps {}

export const Clients: React.FC<ClientsProps> = ({}) => {
  const {
    clients,
    rowsPerPage,
    setRowsPerPage,
    sortBy,
    setSortBy,
    shouldShowDrawer,
    setShouldShowDrawer,
    onClientRowClick,
    clientIdParam,
    onOpenNewClientDrawer,
    loading,
  } = useClients();

  if (loading) {
    return (
      <div className="absolute top-1/3 left-1/2 -translate-x-1/2">
        <Loading />
      </div>
    );
  }

  return (
    <div className="flex flex-col py-4 pr-0 mt-2 gap-6">
      <Drawer
        isOpen={shouldShowDrawer}
        setIsOpen={setShouldShowDrawer}
        backdrop={false}
      >
        {clientIdParam ? (
          <UpdateClient clientId={Number(clientIdParam)} />
        ) : (
          <AddClient setCloseParent={setShouldShowDrawer} key={Date.now()} />
        )}
      </Drawer>
      <div className="flex items-center justify-between">
        <div className="flex gap-2 items-center ml-3">
          <Button
            variant="ghost"
            className="flex gap-2 rounded w-[208px] border-black mr-2"
            onClick={() => onOpenNewClientDrawer()}
          >
            <PlusIcon className="w-4 h-4 stroke-black" />
            <p className="font-semibold ">Add Client</p>
          </Button>
          <p className="text-zinc-500">{clients.length} clients</p>
        </div>
        <div className="flex">
          <Dropdown>
            <DropdownTrigger>
              <Button variant="light" className="flex items-center">
                <p className="text-body-bold font-body-bold text-neutral-900">
                  Sort by
                </p>
                <ChevronDownIcon className="w-3 h-3 stroke-black" />
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions">
              {sortByList.map((sb) => (
                <DropdownItem
                  key={sb}
                  onClick={() => setSortBy(sb)}
                  endContent={
                    <CheckIcon
                      className={classNames("w-3 h-3 stroke-black", {
                        hidden: sb !== sortBy,
                      })}
                    />
                  }
                >
                  {titleize(sb)}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          <Dropdown>
            <DropdownTrigger>
              <Button variant="light" className="flex items-center">
                <p className="text-body-bold font-body-bold text-neutral-900">
                  {rowsPerPage} per page
                </p>
                <ChevronDownIcon className="w-3 h-3 stroke-black" />
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions">
              {perPageList.map((perPage) => (
                <DropdownItem
                  key={perPage}
                  onClick={() => setRowsPerPage(perPage)}
                >
                  {perPage} per page
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <ClientTable
        rowsPerPage={rowsPerPage}
        onClientRowClick={onClientRowClick}
      />
    </div>
  );
};

import * as React from "react";
import { useReadAppointmentScreen } from "./useReadAppointmentScreen";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Spinner,
} from "@nextui-org/react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { format } from "date-fns";
import { CustomClientOption } from "app/schedules/components/crudAppointmentScreens/components/customClientOption";
import { CustomServiceOption } from "app/schedules/components/crudAppointmentScreens/components/customServiceOption";
import { CustomEmployeeOption } from "app/schedules/components/crudAppointmentScreens/components/customEmployeeOption";
import classNames from "classnames";
import { adjustDateToUTC } from "app/utils/formatTime";
import { preserveUtcTimeToLocal } from "app/utils/formatDate";
import { AsButton } from "app/subframe";
import { Avatar } from "app/components/avatar/avatar";

export interface ReadAppointmentScreenProps {
  selectedAppointmentEvent: any;
  getSchedulesOfEmployees: ({ currentDate }: { currentDate?: Date }) => void;
  toggleAppointmentDrawer: (open: boolean) => void;
  setPendingAppointment: (appointment: any) => void;
  closeDrawer: () => void;
}

export const ReadAppointmentScreen: React.FC<ReadAppointmentScreenProps> = ({
  selectedAppointmentEvent,
  getSchedulesOfEmployees,
  toggleAppointmentDrawer,
  setPendingAppointment,
  closeDrawer,
}) => {
  const {
    isEditing,
    setIsEditing,
    employees,
    onUpdateAppointment,
    selectedClient,
    setSelectedClient,
    onHandleClientChange,
    clients,
    selectedServices,
    editingEmployeeInServiceIndex,
    setEditingEmployeeInServiceIndex,
    employeeSelectRef,
    handleEmployeeChange,
    serviceSelectValue,
    onHandleAddService,
    availableServices,
    selectedDate,
    setSelectedDate,
    isDateInputFocused,
    setIsDateInputFocused,
    onRemoveService,
    getSpecificAppointmentGroup,
    cancelEditing,
    onDeleteAppointmentGroup,
    handleClientSearch,
    onTimeChange,
    onDateChange,
  } = useReadAppointmentScreen({
    selectedAppointmentEvent,
    getSchedulesOfEmployees,
    toggleAppointmentDrawer,
    setPendingAppointment,
  });

  const { start, employee } = selectedAppointmentEvent;

  if (!selectedServices) {
    return <Spinner />;
  }

  return (
    <form onSubmit={onUpdateAppointment} className="relative h-[95vh]">
      <div className="flex w-full items-center justify-between mb-7">
        <p className="text-body-bold font-body-bold text-neutral-900">
          Appointments
        </p>
        <div className="flex items-center ">
          {isEditing ? (
            <Button
              variant="light"
              className="rounded"
              onClick={() => cancelEditing()}
            >
              Cancel
            </Button>
          ) : (
            <>
              <AsButton
                variant="ghost"
                className="rounded"
                leadingIcon={"FeatherPen"}
                onClick={() => setIsEditing(true)}
              />
              <Dropdown
                classNames={{
                  content: "rounded-sm",
                }}
              >
                <DropdownTrigger>
                  <Button variant="light" className="rounded">
                    <EllipsisHorizontalIcon className="w-6 h-6" />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu>
                  <DropdownItem
                    variant="flat"
                    key="delete"
                    className="rounded-sm "
                    color="danger"
                    onClick={onDeleteAppointmentGroup}
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <AsButton
                variant="ghost"
                className="rounded"
                leadingIcon={"FeatherX"}
                onClick={() => closeDrawer()}
              />
            </>
          )}
        </div>
      </div>

      <div className="flex items-center gap-2.5 mb-6">
        <Input
          aria-label="Date Input"
          key={
            isEditing && isDateInputFocused
              ? "editing-date"
              : "not-editing-date"
          }
          name="date"
          defaultValue={
            isEditing && isDateInputFocused
              ? format(selectedDate, "yyyy-MM-dd")
              : format(selectedDate, "EEE, MMM d")
          }
          type={isEditing && isDateInputFocused ? "date" : "string"}
          variant="bordered"
          disabled={!isEditing}
          onFocus={() => setIsDateInputFocused(true)}
          onChange={(e: any) => {
            const dateValue = e.target.value;
            if (dateValue) {
              const adjustedDate = adjustDateToUTC(dateValue);
              setSelectedDate(adjustedDate.toISOString());
              onDateChange(adjustedDate);
            }
          }}
          onBlur={() => setIsDateInputFocused(false)}
          classNames={{
            input: "font-semibold text-sm text-zinc-700",
            inputWrapper: "custom-input py-6 !border-2",
          }}
          startContent={
            <span className="text-zinc-500 text-sm font-semibold">On</span>
          }
          className="text-sm text-zinc-500 font-semibold border-zinc-100 bg-transparent"
        />
        {}
        <Input
          id="startTime"
          aria-label="Start time Input"
          disabled={!isEditing}
          defaultValue={
            getSpecificAppointmentGroup(selectedAppointmentEvent.appointment.id)
              .appointments[0]?.startTime
              ? format(
                  preserveUtcTimeToLocal(
                    getSpecificAppointmentGroup(
                      selectedAppointmentEvent.appointment.id
                    ).appointments[0]?.startTime
                  ),
                  "HH:mm"
                )
              : "00:00"
          }
          type="time"
          onChange={onTimeChange}
          name="startTime"
          variant="bordered"
          classNames={{
            input: "font-semibold text-sm text-zinc-700",
            inputWrapper: "custom-input py-6 !border-2",
          }}
          startContent={
            <span className="text-zinc-500 whitespace-nowrap text-sm font-semibold">
              Start at
            </span>
          }
          className="text-sm text-zinc-500 font-semibold border-zinc-100 bg-transparent"
        />
      </div>

      <div className="flex flex-col relative">
        {isEditing && (
          <Button
            isIconOnly
            variant="light"
            className="rounded absolute top-0 right-0"
          >
            <TrashIcon className="w-4 h-4 text-zinc-500" />
          </Button>
        )}
        {selectedClient ? (
          <div className="flex flex-col relative">
            {isEditing && (
              <Button
                isIconOnly
                variant="light"
                onClick={() => setSelectedClient(null)}
                className="rounded absolute top-0 right-0"
              >
                <TrashIcon className="w-4 h-4 text-zinc-500" />
              </Button>
            )}
            <div className="flex pb-4">
              <Avatar
                src={selectedClient.avatar}
                name={selectedClient.name}
                size={56}
                type="character"
              />
              <div className="flex flex-col justify-between ml-4">
                <p className="font-semibold pt-0.5">{selectedClient.name}</p>
                <p className="text-zinc-500 text-sm">
                  Client since {format(selectedClient.createdAt, "M.d.yyyy")}
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-4">
                <p className="text-zinc-500 w-[56px] text-sm">Phone:</p>
                <p className="font-semibold ">
                  {selectedClient.phone || "(###) ###-####"}
                </p>
              </div>
              <div className="flex items-center gap-4">
                <p className="text-zinc-500 w-[56px] text-sm">Email:</p>
                <p className="font-semibold ">
                  {selectedClient.email || "###"}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <AsyncSelect
            cacheOptions
            loadOptions={handleClientSearch as any}
            onChange={onHandleClientChange}
            className="text-zinc-500 text-sm"
            placeholder="Search or select a client"
            components={{
              Option: CustomClientOption,
            }}
            options={clients.map((c) => ({
              value: c.id,
              label: c.name,
              phone: c.phone,
              email: c.email,
              createdAt: c.createdAt,
              avatar: c.avatar,
            }))}
          />
        )}
        <Divider className="my-6" />

        <div className="flex flex-col gap-6 mb-6">
          {selectedServices.map((service: any, index: number) => (
            <div className="flex items-center justify-between" key={service.id}>
              <div className="flex flex-col gap-2">
                <p className="text-body-bold font-body-bold text-neutral-900">
                  {service.title}
                </p>
                <div className="flex gap-1 items-center">
                  <span className="text-zinc-500 text-sm">with</span>
                  <div
                    className={classNames(
                      "font-semibold text-zinc-900 relative mr-3",
                      {
                        "cursor-pointer hover:underline": isEditing,
                      }
                    )}
                    onClick={() =>
                      isEditing && setEditingEmployeeInServiceIndex(index)
                    }
                  >
                    {editingEmployeeInServiceIndex === index && isEditing ? (
                      <div
                        ref={employeeSelectRef}
                        className="absolute -top-5 w-[200px]"
                      >
                        <Select
                          placeholder="Select an employee"
                          components={{
                            Option: CustomEmployeeOption,
                          }}
                          onChange={(selectedOption) =>
                            handleEmployeeChange(index, selectedOption)
                          }
                          className="text-zinc-500 text-sm"
                          options={employees.map((emp) => ({
                            value: emp.id,
                            label: emp.name,
                            services: emp.services,
                            selectedDate,
                            availabilities: emp.availabilities,
                            businessServiceId:
                              selectedServices[index]?.businessService.id, // Pass the specific business service ID
                          }))}
                          onBlur={() => setEditingEmployeeInServiceIndex(null)}
                          value={
                            selectedServices[index]?.employee
                              ? {
                                  value: selectedServices[index]?.employee?.id,
                                  label:
                                    selectedServices[index]?.employee?.name,
                                }
                              : null
                          }
                          menuIsOpen={editingEmployeeInServiceIndex === index}
                        />
                      </div>
                    ) : (
                      <p className="text-sm">
                        <span className="font-semibold text-zinc-900">
                          {service?.employee?.name}
                        </span>
                      </p>
                    )}
                  </div>
                  <p
                    className={classNames("flex gap-1 text-sm", {
                      "ml-[200px]": editingEmployeeInServiceIndex === index,
                    })}
                  >
                    <span className="text-zinc-500 text-sm">At</span>
                    <span className="font-semibold text-zinc-900">
                      {service.startAtTime}
                    </span>
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <p className="text-body-bold font-body-bold text-neutral-900">
                  {service.price}
                </p>
                {isEditing && (
                  <Button
                    type="button"
                    onClick={() => onRemoveService(index)}
                    variant="light"
                    isIconOnly
                    className="rounded"
                  >
                    <TrashIcon className="w-4 h-4" />
                  </Button>
                )}
              </div>
            </div>
          ))}
        </div>

        {isEditing && (
          <Select
            placeholder="Select a service"
            components={{
              Option: CustomServiceOption,
            }}
            onChange={onHandleAddService}
            value={serviceSelectValue}
            className="text-zinc-500 text-sm"
            options={availableServices?.map((category) => ({
              label: category.name,
              options: category.services.map((service: any) => ({
                value: service.id,
                label: service.title,
                price: service.price,
                status: service.status,
                employee,
              })),
            }))}
          />
        )}
      </div>

      {isEditing && (
        <Button
          type="submit"
          className="rounded-sm w-full bottom-0 bg-black text-white absolute"
        >
          Update appointment
        </Button>
      )}
    </form>
  );
};

"use client";

// @subframe/sync-disable

/*
 * Documentation:
 * Mobile Nav bar — https://app.subframe.com/feb895689ba0/library?component=Mobile+Nav+bar_3adab306-a34b-4ca6-ad0b-bbae87d5cb65
 */

import React from "react";
import * as SubframeCore from "@subframe/core";
import InactiveScheduleIcon from "app/assets/icons/mobileNavbar/inactiveSchedule.svg";
import ActiveScheduleIcon from "app/assets/icons/mobileNavbar/activeSchedule.svg";
import InactiveSalesIcon from "app/assets/icons/mobileNavbar/inactiveSales.svg";
import ActiveSalesIcon from "app/assets/icons/mobileNavbar/activeSales.svg";
import InactiveClientsIcon from "app/assets/icons/mobileNavbar/inactiveClients.svg";
import ActiveClientsIcon from "app/assets/icons/mobileNavbar/activeClients.svg";
import InactiveSettingsIcon from "app/assets/icons/mobileNavbar/inactiveHome.svg";
import ActiveSettingsIcon from "app/assets/icons/mobileNavbar/activeHome.svg";
import { Path } from "app/path";
import { AsIconButton } from "./AsIconButton";
import { AsButton } from "./AsButton";
import { Link } from "react-router-dom";

interface MobileNavBarRootProps extends React.HTMLAttributes<HTMLDivElement> {
  image?: string;
  image2?: string;
  image3?: string;
  image4?: string;
  className?: string;
}

const MobileNavBarRoot = React.forwardRef<HTMLElement, MobileNavBarRootProps>(
  function MobileNavBarRoot(
    {
      image,
      image2,
      image3,
      image4,
      className,
      ...otherProps
    }: MobileNavBarRootProps,
    ref
  ) {
    const navLinks = [
      {
        inactiveImage: InactiveScheduleIcon,
        activeImage: ActiveScheduleIcon,
        to: Path.HOME,
      },
      {
        inactiveImage: InactiveSalesIcon,
        activeImage: ActiveSalesIcon,
        to: Path.SALES,
      },
      {
        inactiveImage: InactiveClientsIcon,
        activeImage: ActiveClientsIcon,
        to: Path.CLIENTS,
      },
      {
        inactiveImage: InactiveSettingsIcon,
        activeImage: ActiveSettingsIcon,
        to: Path.SETTINGS,
      },
    ];

    return (
      <div
        className={SubframeCore.twClassNames(
          "flex h-16 w-full items-center justify-between rounded-lg bg-[#ffffffb3] px-2 py-2 shadow-pop-up-shadow backdrop-blur-sm",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        {navLinks.map((navLink, index) => {
          const isSelected = window.location.pathname === navLink.to;

          return (
            <Link
              to={navLink.to}
              className="flex items-center justify-center gap-4 rounded-md px-2 py-2"
            >
              <img
                key={index}
                src={isSelected ? navLink.activeImage : navLink.inactiveImage}
                className="h-8 w-8 flex-none object-cover"
              />
            </Link>
          );
        })}
      </div>
    );
  }
);

export const MobileNavBar = MobileNavBarRoot;

import * as React from "react";
import { UserAuth, UserContextType } from "app/context/AuthContext";
import { getFormValues } from "app/utils/getFormValues";
import { useNavigate } from "react-router";
import { Path } from "app/path";
import firebaseAuth from "app/utils/firebase";

export const useSignin = () => {
  const navigate = useNavigate();
  const { emailPassSignIn, init } = UserAuth() as UserContextType;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const handleSigninSubmit = async (event: any) => {
    event.preventDefault();
    const { email, password } = getFormValues(event.target);

    try {
      setLoading(true);
      await emailPassSignIn(email, password);
      const user = firebaseAuth.currentUser;
      await init(user);
      navigate(Path.HOME);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return { handleSigninSubmit, loading, showPassword, setShowPassword };
};

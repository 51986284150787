import { Avatar } from "app/components/avatar/avatar";
import * as React from "react";

export const CustomResourceHeader: React.FC<any> = ({ resource }) => {
  return (
    <div
      onScroll={(e) => console.log(e)}
      className="flex items-center justify-between bg-white -translate-x-1 p-2 w-full border-b border-zinc-100 absolute h-[60px] z-10"
    >
      <div
        key={resource.resourceId}
        className="flex justify-center items-center w-full mx-2"
      >
        <Avatar src={resource.avatar} name={resource.resourceTitle} size={32} />
        <p className="ml-2 text-sm font-semibold">{resource.resourceTitle}</p>
      </div>
    </div>
  );
};

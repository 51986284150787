import { Button, Input, Snippet } from "@nextui-org/react";
import { SectionHeader } from "app/components/sectionHeader";
import * as React from "react";
import { useBusinessDetails } from "./useBusinessDetails";
import { InputEdit } from "app/components/inputEdit/InputEdit";

export interface BusinessDetailsProps {}

export const BusinessDetails: React.FC<BusinessDetailsProps> = (props) => {
  const { isFormChanged, account, onUpdateAccount } = useBusinessDetails();

  return (
    <div className="flex flex-col w-full gap-6">
      <SectionHeader title="Business Details" />
      <div className="p-4 gap-10 flex flex-col rounded border border-[#E4E4E7]">
        <InputEdit
          defaultValue={account?.name}
          label="Business name"
          name="name"
          onSave={(value) => onUpdateAccount({ ...value })}
          valueClassName="text-body-bold font-body-bold text-neutral-900"
        />
        <InputEdit
          defaultValue={account?.description}
          label="Business description"
          name="description"
          onSave={(value) => onUpdateAccount({ ...value })}
          valueClassName="text-body-bold font-body-bold text-neutral-900"
        />
        <InputEdit
          defaultValue={account?.website}
          label="Website"
          name="website"
          onSave={(value) => onUpdateAccount({ ...value })}
          valueClassName="text-body-bold font-body-bold text-neutral-900"
        />
        <div className="flex flex-col gap-3">
          <p className="text-sm text-[#71717A]">Booking URL</p>
          <Snippet
            classNames={{
              base: "bg-transparent pl-0 pt-0 font-inter",
              pre: "font-inter font-semibold text-medium text-[#3F3F46]",
            }}
            hideSymbol
          >
            {`https://book.heyallset.com${account?.bookingLink}`}
          </Snippet>
        </div>
      </div>
    </div>
  );
};

import {
  Table,
  Pagination,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
} from "@nextui-org/react";
import { useClientTable } from "./useClientTable";
import * as React from "react";
import { Avatar } from "app/components/avatar/avatar";
import { useBreakpoint } from "app/utils/useBreakpoint";
import { truncate } from "lodash";

export interface ClientTableProps {
  rowsPerPage: number;
  onClientRowClick: (id: number) => void;
}

export const ClientTable: React.FC<ClientTableProps> = ({
  rowsPerPage,
  onClientRowClick,
}) => {
  const {
    isLoading,
    setIsLoading,
    page,
    sortDescriptor,
    setSortDescriptor,
    sortedItems,
    totalCount,
    handlePageChange,
  } = useClientTable({
    rowsPerPage,
  });

  const { isBelowSm } = useBreakpoint("sm");

  const renderCell = React.useCallback((client: any, columnKey: any) => {
    const cellValue = client[columnKey];

    switch (columnKey) {
      case "id":
        return (
          <div className="flex flex-col">
            <p className="text-gray-800 text-sm">{cellValue}</p>
          </div>
        );
      case "name":
        return (
          <div className="flex items-center gap-2">
            <Avatar
              src={client.avatar}
              name={cellValue}
              type="character"
              className="rounded w-6 h-8"
            />
            <p className="text-gray-800 text-sm">
              {isBelowSm
                ? truncate(cellValue, { length: 6, omission: ".." })
                : cellValue}
            </p>
          </div>
        );

      case "email":
        return (
          <div className="flex flex-col">
            <p className="text-gray-800 text-sm">{cellValue}</p>
          </div>
        );
      case "phone":
        return (
          <div className="flex flex-col">
            <p className="text-gray-800 text-sm">{cellValue}</p>
          </div>
        );
      default:
        return cellValue;
    }
  }, []);

  const pages = Math.ceil(totalCount / rowsPerPage);

  return (
    <Table
      isStriped
      bottomContent={
        <div className="flex w-full justify-center">
          <Pagination
            isCompact
            showControls={pages > 1}
            variant="light"
            color="default"
            classNames={{
              item: "transparent",
            }}
            page={page}
            total={pages}
            onChange={(page) => handlePageChange(page)}
          />
        </div>
      }
      isHeaderSticky
      sortDescriptor={sortDescriptor}
      onSortChange={setSortDescriptor}
      classNames={{
        base: "h-[88vh]",
        wrapper: "min-h-[222px] border-none shadow-none p-0",
        thead: "bg-white shadow-none border-none !rounded-none",
        th: "bg-transparent font-normal",
        tr: "h-12 cursor-pointer hover:bg-[#D1F4E0]",
      }}
    >
      <TableHeader>
        <TableColumn allowsSorting key="id">
          #
        </TableColumn>
        <TableColumn allowsSorting key="name">
          Name
        </TableColumn>
        <TableColumn allowsSorting key="email">
          Email
        </TableColumn>
        <TableColumn allowsSorting key="phone">
          Phone
        </TableColumn>
      </TableHeader>
      <TableBody items={sortedItems}>
        {(item: any) => (
          <TableRow key={item.id} onClick={() => onClientRowClick(item.id)}>
            {(columnKey) => (
              <TableCell className="">{renderCell(item, columnKey)}</TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

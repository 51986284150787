import * as React from "react";
import { useHowManyServiceProviders } from "./useHowManyServiceProviders";
import { AsButton } from "app/subframe";
import { OnboardingCard } from "app/subframe/components/OnboardingCard";
import { OnboardingStep } from "app/redux/onboardingSlice";
import p1svg from "app/assets/images/onboarding/1.svg";
import p24svg from "app/assets/images/onboarding/2-4.svg";
import p515svg from "app/assets/images/onboarding/5-15.svg";
import p16svg from "app/assets/images/onboarding/16.svg";

export interface HowManyServiceProvidersProps {
  step: number;
  totalSteps: number;
  setCurrentStep: (step: string) => void;
}

const choiceCardData = [
  {
    amount: 1,
    title: "Just me",
    description: "1",
    value: "just_me",
    img: <img src={p1svg} className="w-8 h-full " />,
  },
  {
    amount: 3,
    title: "Small",
    description: "2-4",
    value: "small",
    img: <img src={p24svg} className="w-full h-full " />,
  },
  {
    amount: 8,
    title: "Medium",
    description: "5-15",
    value: "medium",
    img: <img src={p515svg} className="w-full h-full " />,
  },
  {
    amount: 24,
    title: "Large",
    description: "16+",
    value: "large",
    img: <img src={p16svg} className="w-full h-full " />,
  },
];

export const HowManyServiceProviders: React.FC<
  HowManyServiceProvidersProps
> = ({ step, totalSteps, setCurrentStep }) => {
  const { teamSize, setTeamSize, onSubmit, loading } =
    useHowManyServiceProviders({
      setCurrentStep,
    });
  return (
    <div className="flex h-full w-full flex-col items-center gap-16 bg-millet-500 px-10 py-10 mobile:flex-col mobile:gap-14 mobile:px-4 mobile:pt-10 mobile:pb-20">
      <div className="flex w-full flex-col items-start gap-4 mobile:items-center mobile:justify-start">
        <img
          className="h-4 w-20 flex-none object-contain mobile:object-contain"
          src="https://res.cloudinary.com/subframe/image/upload/v1727730304/uploads/3988/jkyyafpujkyze6ijiogy.svg"
        />
      </div>
      <div className="flex flex-col items-center gap-16 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:flex-col mobile:gap-14">
        <div className="flex flex-col items-center justify-center gap-2 mobile:h-auto mobile:w-full mobile:flex-none">
          <span className="text-[14px] font-[400] leading-[20px] text-neutral-700">
            Step {step} of {totalSteps}
          </span>
          <span className="text-heading-2 font-heading-2 text-neutral-700 text-center mobile:h-auto mobile:w-full mobile:flex-none">
            How many staff do you employ?
          </span>
        </div>
        <div className="flex items-center gap-10 mobile:h-auto mobile:w-full mobile:flex-none mobile:flex-col mobile:flex-wrap mobile:gap-4">
          <div className="flex items-center gap-10 mobile:h-auto mobile:w-full mobile:flex-none mobile:grid mobile:grid-cols-2 mobile:gap-4">
            {choiceCardData.map((choiceCard) => (
              <OnboardingCard
                className="mobile:h-52 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0"
                size={choiceCard.title}
                number={choiceCard.description.toString()}
                onClick={() => setTeamSize(choiceCard.value)}
                selected={teamSize === choiceCard.value}
                image={choiceCard.img}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-col items-center gap-16 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:items-center mobile:justify-start">
          <div className="flex w-112 flex-col items-center gap-10 mobile:h-auto mobile:w-full mobile:flex-none">
            <AsButton
              className="h-12 w-full flex-none"
              variant="brand"
              leadingIcon={null}
              trailingIcon={null}
              text="Continue"
              size="md"
              loader={loading}
              disabled={!teamSize}
              onClick={() => onSubmit()}
            />
            <AsButton
              variant="link"
              leadingIcon={null}
              trailingIcon={null}
              text="Back"
              size="xs"
              onClick={() => setCurrentStep(OnboardingStep.BUSINESS_INFO)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
